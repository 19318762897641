import { getGetCurrencyUnitsMock } from '@/libs/mocks/handlers/currency-units';
import { faker } from '@faker-js/faker/locale/ja';
import { GetApCurrencyRatesResponse } from 'ap-openapi';
import { toISODateString } from 'date-util';
import dayjs from 'dayjs';
import { delay, http, HttpResponse } from 'msw';
let currencies: GetApCurrencyRatesResponse = {};
faker.seed(1);

type AddCurrencyRequestBody = {
  currency: string;
  start_date: string;
  end_date: string;
  value: string;
};

export const handlers = [
  http.get('*/api/js/ap_currency_rates', async ({ request }) => {
    const url = new URL(request.url);
    let currency;
    const currencyParam = url.searchParams.get('currency');
    if (currencyParam) {
      currency = findCurrency(currencyParam);
    }

    currencies = getGetApCurrencyRatesMock(currency?.name);

    await delay(1000);
    return new HttpResponse(JSON.stringify(currencies), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.post('*/api/js/ap_currency_rates', async ({ request }) => {
    await delay(1000);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const currency = (await request.json()) as AddCurrencyRequestBody;
    const newCurrency = findCurrency(currency.currency);

    return new HttpResponse(
      JSON.stringify({
        ...currency,
        currency: newCurrency?.name,
        is_exist: currencies.ap_currency_rates
          ? currencies.ap_currency_rates.find(
              (c) => c.currency === newCurrency?.name
            )
          : false,
      }),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.get('*/api/js/ap_currency_rates/:id', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getGetApCurrencyRateMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.put('*/api/js/ap_currency_rates/:id', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getUpdateApCurrencyRateMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.delete('*/api/js/ap_currency_rates/:id', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 204,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.get('*/api/js/ap_currency_rates/currency/:currency', async () => {
    await delay(1000);
    return new HttpResponse(
      JSON.stringify(getGetApCurrencyRateByCurrencyMock()),
      {
        status: 200,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }),
  http.delete('*/api/js/ap_currency_rates/currency/:currency', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 204,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.post('*/api/js/export_ap_currency_rates', async () => {
    await delay(1000);
    return new HttpResponse(JSON.stringify(getExportApCurrencyRatesMock()), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }),
  http.post('*/api/js/import_ap_currency_rates', async () => {
    await delay(1000);
    return new HttpResponse(null, {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
        'x-is-async': 'false',
      },
    });
  }),
];

export const getGetApCurrencyRatesMock = (
  currency: string | null | undefined = undefined
) => ({
  ap_currency_rates: Array.from(
    { length: faker.number.int({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    currency: currency || faker.finance.currencyCode(),
    end_date: toISODateString(dayjs(faker.date.future())),
    id: faker.string.uuid(),
    start_date: toISODateString(dayjs(faker.date.past())),
    updated_at: toISODateString(dayjs(faker.date.anytime())),
    value: faker.number.int({ min: 50, max: 500 }),
  })),
  pagination: {
    has_next_page: true,
    has_previous_page: true,
    page: 1,
    per_page: 25,
    total_pages: 100,
    total_records: 1000,
  },
});

export const getGetApCurrencyRateMock = () => ({
  currency: faker.finance.currencyCode(),
  end_date: toISODateString(dayjs(faker.date.future())),
  id: faker.string.uuid(),
  start_date: toISODateString(dayjs(faker.date.past())),
  updated_at: toISODateString(dayjs(faker.date.anytime())),
  value: faker.number.int({ min: 50, max: 500 }),
});

export const getUpdateApCurrencyRateMock = () => ({
  currency: faker.finance.currencyCode(),
  end_date: toISODateString(dayjs(faker.date.future())),
  id: faker.string.uuid(),
  start_date: toISODateString(dayjs(faker.date.past())),
  updated_at: toISODateString(dayjs(faker.date.anytime())),
  value: faker.number.int({ min: 50, max: 500 }),
});

export const getGetApCurrencyRateByCurrencyMock = () => ({
  ap_currency_rates: Array.from(
    { length: faker.number.int({ min: 1, max: 10 }) },
    (_, i) => i + 1
  ).map(() => ({
    currency: faker.finance.currencyCode(),
    end_date: toISODateString(dayjs(faker.date.future())),
    id: faker.string.uuid(),
    start_date: toISODateString(dayjs(faker.date.past())),
    updated_at: toISODateString(dayjs(faker.date.anytime())),
    value: faker.number.int({ min: 50, max: 500 }),
  })),
  pagination: {
    has_next_page: true,
    has_previous_page: true,
    page: 1,
    per_page: 25,
    total_pages: 100,
    total_records: 1000,
  },
});
export const getExportApCurrencyRatesMock = () => faker.word.sample();
const findCurrency = (currency: string) => {
  const currencies = getGetCurrencyUnitsMock(1).currencies;
  return currencies.find((c) => c.id === currency);
};
